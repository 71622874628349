@import "reset";

html {
	font-size: 62.5%;
}

///////////
// Links //
///////////
a {
	text-decoration: none;
	line-height: inherit;
}

/*a:hover { color: #11639d; }*/
/*a:focus { color: #cc4714; outline: none; }*/
/*p a, p a:visited { line-height: inherit; }*/

strong {
	font-weight: bold;
}

em {
	font-style: italic;
}
