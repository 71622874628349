////////////
// MIXINS //
////////////

@mixin border-radius($radius: 5px) {
	border-radius: $radius;
	-moz-border-radius: $radius;
	-webkit-border-radius: $radius;
	opacity: 1;
}

@mixin transition($time: 0.3s) {
	-webkit-transition: all $time ease-out; /* Saf3.2+, Chrome */
	-moz-transition: all $time ease-out; /* FF4+ */
	-ms-transition: all $time ease-out; /* IE10? */
	-o-transition: all $time ease-out; /* Opera 10.5+ */
	transition: all $time ease-out;
}

@mixin link-color($normal, $hover) {
	a {
		text-decoration: none;
		color: $normal;
		&:hover {
			color: $hover;
			@include transition;
		}
	}
}

@mixin link-color-single($linkcolor) {
	@include link-color($linkcolor, $linkcolor);
}

@mixin horizontal-list($padding-right: 0, $padding-left: 0) {
	ul {
		padding: 0;
		li {
			display: inline;
			padding-right: $padding-right;
			padding-left: $padding-left;
		}
	}
}

.hide {
	display: none;
}

.left {
	float: left;
}

.right {
	float: right;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

@mixin text-center {
	text-align: center;
}

.image {
	&.center {
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		display: block;
	}

	&.caption {
	}

	a,
	a:hover {
		padding: 0;
		margin: 0;
		border: 0;
		background-color: transparent;
		color: transparent;
		text-decoration: none;

		@include border-radius(0);
		@include transition(0);
	}
}
