////////////
// COLORS //
////////////

$bg: #121723;
$bg-accent: #343f50;
$offwhite: #fdfdfd;
$blue: #61a6d0;
$navy: #1f4476;
$orange: #ffc53b;
$orange-dark: #fc951e;
$orange-burnt: #d24727;
$light: #6e8097;
$purple: #7750cb;
$purple-light: #c48dff;
$light-blue: #c4c8ff;
$main-blue: #3973a8;
$bright-blue: #3ab3fe;
$strong-blue: #007cef;
$blue2: #479cd1;
$blue3: #2d3e5d;

$graphite: #434950;
$pink: #ea4c89;
$pink-bright: #fa2573;
$green: #8aba56;
$lime: #a6e32d;
$red: #e52222;
$red-bright: #c00;
$teal: #67d9f0;
$grey: #586e75;
