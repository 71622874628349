$mode: dark;

$solar-yellow: #b58900;
$solar-orange: #cb4b16;
$solar-red: #dc322f;
$solar-magenta: #d33682;
$solar-violet: #6c71c4;
$solar-blue: #268bd2;
$solar-cyan: #2aa198;
$solar-green: #859900;

$base03: #002b36; //darkest blue
$base02: #073642; //dark blue
$base01: #586e75; //darkest gray
$base00: #657b83; //dark gray
$base0: #839496; //medium gray
$base1: #93a1a1; //medium light gray
$base2: #eee8d5; //cream
$base3: #fdf6e3; //white

@if $mode == light {
	$_base03: $base03;
	$_base02: $base02;
	$_base01: $base01;
	$_base00: $base00;
	$_base0: $base0;
	$_base1: $base1;
	$_base2: $base2;
	$_base3: $base3;

	$base03: $_base3;
	$base02: $_base2;
	$base01: $_base1;
	$base00: $_base0;
	$base0: $_base00;
	$base1: $_base01;
	$base2: $_base02;
	$base3: $_base03;
}

/* non highlighted code colors */
$pre-bg: $base03;
$pre-border: darken($base02, 5);
$pre-color: $base1;

.c {
	color: $base01 !important;
	font-style: italic !important;
} /* Comment */
.cm {
	color: $base01 !important;
	font-style: italic !important;
} /* Comment.Multiline */
.cp {
	color: $base01 !important;
	font-style: italic !important;
} /* Comment.Preproc */
.c1 {
	color: $base01 !important;
	font-style: italic !important;
} /* Comment.Single */
.cs {
	color: $base01 !important;
	font-weight: bold !important;
	font-style: italic !important;
} /* Comment.Special */
.err {
	color: $solar-red !important;
	background: none !important;
} /* Error */
.k {
	color: $solar-orange !important;
} /* Keyword */
.o {
	color: $base1 !important;
	font-weight: bold !important;
} /* Operator */
.p {
	color: $base1 !important;
} /* Operator */
.ow {
	color: $solar-cyan !important;
	font-weight: bold !important;
} /* Operator.Word */
.gd {
	color: $base1 !important;
	background-color: mix($solar-red, $base03, 25%) !important;
	display: inline-block;
} /* Generic.Deleted */
.gd .x {
	color: $base1 !important;
	background-color: mix($solar-red, $base03, 35%) !important;
	display: inline-block;
} /* Generic.Deleted.Specific */
.ge {
	color: $base1 !important;
	font-style: italic !important;
} /* Generic.Emph */
//.gr     { color: #aa0000 }                                          /* Generic.Error */
.gh {
	color: $base01 !important;
} /* Generic.Heading */
.gi {
	color: $base1 !important;
	background-color: mix($solar-green, $base03, 20%) !important;
	display: inline-block;
} /* Generic.Inserted */
.gi .x {
	color: $base1 !important;
	background-color: mix($solar-green, $base03, 40%) !important;
	display: inline-block;
} /* Generic.Inserted.Specific */
//.go     { color: #888888 }                                          /* Generic.Output */
//.gp     { color: #555555 }                                          /* Generic.Prompt */
.gs {
	color: $base1 !important;
	font-weight: bold !important;
} /* Generic.Strong */
.gu {
	color: $solar-violet !important;
} /* Generic.Subheading */
//.gt     { color: #aa0000 }                                          /* Generic.Traceback */
.kc {
	color: $solar-green !important;
	font-weight: bold !important;
} /* Keyword.Constant */
.kd {
	color: $solar-blue !important;
} /* Keyword.Declaration */
.kp {
	color: $solar-orange !important;
	font-weight: bold !important;
} /* Keyword.Pseudo */
.kr {
	color: $solar-magenta !important;
	font-weight: bold !important;
} /* Keyword.Reserved */
.kt {
	color: $solar-cyan !important;
} /* Keyword.Type */
.n {
	color: $solar-blue !important;
}
.na {
	color: $solar-blue !important;
} /* Name.Attribute */
.nb {
	color: $solar-green !important;
} /* Name.Builtin */
.nc {
	color: $solar-magenta !important;
} /* Name.Class */
.no {
	color: $solar-yellow !important;
} /* Name.Constant */
//.ni     { color: #800080 }                                          /* Name.Entity */
.nl {
	color: $solar-green !important;
}
.ne {
	color: $solar-blue !important;
	font-weight: bold !important;
} /* Name.Exception */
.nf {
	color: $solar-blue !important;
	font-weight: bold !important;
} /* Name.Function */
.nn {
	color: $solar-yellow !important;
} /* Name.Namespace */
.nt {
	color: $solar-blue !important;
	font-weight: bold !important;
} /* Name.Tag */
.nx {
	color: $solar-yellow !important;
}
.bp {
	font-weight: bold !important;
} /* Name.Builtin.Pseudo */
//.vc     { color: #008080 }                                          /* Name.Variable.Class */
.vg {
	color: $solar-blue !important;
} /* Name.Variable.Global */
.vi {
	color: $solar-blue !important;
} /* Name.Variable.Instance */
.nv {
	color: $solar-blue !important;
} /* Name.Variable */
//.w      { color: #bbbbbb }                                          /* Text.Whitespace */
.mf {
	color: $solar-cyan !important;
} /* Literal.Number.Float */
.m {
	color: $solar-cyan !important;
} /* Literal.Number */
.mh {
	color: $solar-cyan !important;
} /* Literal.Number.Hex */
.mi {
	color: $solar-cyan !important;
} /* Literal.Number.Integer */
//.mo     { color: #009999 }                                          /* Literal.Number.Oct */
.s {
	color: $solar-cyan !important;
} /* Literal.String */
//.sb     { color: #d14 }                                             /* Literal.String.Backtick */
//.sc     { color: #d14 }                                             /* Literal.String.Char */
.sd {
	color: $solar-cyan !important;
} /* Literal.String.Doc */
.s2 {
	color: $solar-cyan !important;
} /* Literal.String.Double */
.se {
	color: $solar-red !important;
} /* Literal.String.Escape */
//.sh     { color: #d14 }                                             /* Literal.String.Heredoc */
.si {
	color: $solar-blue !important;
} /* Literal.String.Interpol */
//.sx     { color: #d14 }                                             /* Literal.String.Other */
.sr {
	color: $solar-cyan !important;
} /* Literal.String.Regex */
.s1 {
	color: $solar-cyan !important;
} /* Literal.String.Single */
//.ss     { color: #990073 }                                          /* Literal.String.Symbol */
//.il     { color: #009999 }                                          /* Literal.Number.Integer.Long */
